.table__row__client__control:hover {
  background-color: #f2f3f3;
}

.table__row__client__control td:nth-child(1) {
  border-left: none;
  border-right: none;
  cursor: pointer;
  align-items: center;
  width: 20px;
}

.table__row__client__control td:nth-child(2) {
  border-left: none;
  border-right: none;
  cursor: pointer;
}

.table__row__client__control td .agroup {
  display: flex;
  align-items: center;
  justify-content: right;
  column-gap: 1rem;
}

.table__row__client__control td {
  border-left: 1px solid #d8d8d8;
  padding: 10px 10px !important;
}

.table__row__client__control td span {
  max-width: 30ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

@media (max-width: 768px) {
  .table__row__client__control td {
    font-size: 14px;
    padding: 5px 0 !important;
  }

  .table__row__client__control td .agroup {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .table__row__client__control td:nth-child(1) {
    position: relative;
    width: 100%;
    padding: 0 10px;
  }

  .table__row__client__control td:nth-child(1) > div {
    position: absolute;
    top: 80%;
    left: 0;
  }

  .table__row__client__control td {
    font-size: 12px;
    padding: 5px 5px !important;
  }

  .table__row__client__control td .agroup {
    font-size: 12px;

    display: flex;

    column-gap: 0.2rem;
  }
}
