.table__row__backup:hover {
  background-color: #f2f3f3;
}

.table__row__backup td:nth-child(1) {
  border-left: none;
  border-right: none;
  cursor: pointer;
}

.table__row__backup td .agroup {
  display: flex;
  align-items: center;
  justify-content: right;
  column-gap: 1rem;
}

.table__row__backup td {
  border-left: 1px solid #d8d8d8;
  padding: 10px 10px !important;
}

.table__row__backup td span {
  max-width: 30ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.table__row__backup td input[type='checkbox'] {
  position: relative;
  cursor: pointer;
}

.table__row__backup td input[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: #e9e9e9;
}

.table__row__backup td input[type='checkbox']:checked:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: #1e80ef;
}

.table__row__backup td input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1px;
  left: 5px;
}

.table__row__backup td.row__icons {
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.table__row__backup td.row__icons > div {
  display: flex;
  align-items: center;
  justify-content: right;
  column-gap: 8px;
  padding: 5px;
}

.table__row__backup td.row__icons > div a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table__row__backup td.row__icons img {
  width: 24px;
}

@media (max-width: 768px) {
  .table__row__backup td {
    font-size: 14px;
  }

  .table__row__backup td .agroup {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .table__row__backup td {
    font-size: 12px;
  }

  .table__row__backup td .agroup {
    font-size: 12px;

    display: flex;
  }
}
