@import '@radix-ui/colors/blackA.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/red.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button {
  all: unset;
}

.inactive__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #b24304;
  border-radius: 5px;
}

.AlertDialogOverlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  z-index: 5;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.AlertDialogContent {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 6;
  font-family: var(--font-regular);
}
.AlertDialogContent:focus {
  outline: none;
}

.AlertDialogTitle {
  margin: 0 0 15px 0;
  color: var(--ultra-light-gray);
  font-size: 1.125rem;
  font-family: var(--font-bold);
}

.AlertDialogDescription {
  margin: 0 0 25px 0;
  color: var(--ultra-light-gray);
  font-size: 1rem;
  line-height: 1.5;
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}
.Button.red {
  background-color: var(--red);
  color: #fff;
}
.Button.red:hover {
  background-color: var(--red-hover);
}
.Button.red:focus {
  box-shadow: 0 0 0 2px var(--red-hover);
}
.Button.green {
  background-color: var(--green);
  color: #fff;
}
.Button.green:hover {
  background-color: var(--green-hover);
}
.Button.green:focus {
  box-shadow: 0 0 0 2px var(--green-hover);
}

.active__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #26aa2c;
  border-radius: 5px;
}

.inactive__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #b24304;
  border-radius: 5px;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
