.App .form__box input:-webkit-autofill,
.App .form__box input:-webkit-autofill:hover,
.App .form__box input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  box-shadow: none;
  -webkit-box-shadow: 0 0 0px 1000px var(--ultra-light-gray) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
}

.App .form__box {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 3rem;
}

.App form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 344px;

  row-gap: 1.25rem;
}

.App form .logo__app {
  width: 324px;

  margin-bottom: 74px;
}

.App form .input__box {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 21px;

  background-color: var(--ultra-light-gray);

  border-radius: 8px;
}

.App form .input__box input {
  outline: none;
  border: none;
  background: none;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;
}

.App form .input__box input::placeholder {
  color: #fff;
}

.App form .input__box input:-webkit-autofill,
.App form .input__box input:-webkit-autofill:hover,
.App form .input__box input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  box-shadow: none;
  -webkit-box-shadow: 0 0 0px 1000px var(--ultra-light-gray) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.App form button {
  background-color: var(--green);
  border-radius: 8px;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  border: none;
  outline: none;

  width: 100%;
  height: 65px;

  cursor: pointer;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.App form a {
  font-family: var(--font-bold);
  color: var(--ultra-light-gray);
  font-size: 14px;
}

.App form hr {
  width: 100%;
  height: 1px;
  background-color: var(--ultra-light-gray);
  border: none;
}

.App .create__account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.3rem;
}

.App .create__account h5,
.App .create__account a {
  font-family: var(--font-bold);
  color: var(--ultra-light-gray);
}

.App form .button__loading span {
  visibility: hidden;
  opacity: 0;
}

.App form .button__loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 876px) {
  .App {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: 100vh;
  }

  .App .image {
    display: none;
  }
}
