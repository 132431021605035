* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;

  font-size: 16px;
}

@font-face {
  font-family: 'IBM Plex Sans Bold';
  src: url('./assets/fonts/IBMPlexSans-Bold.ttf');
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('./assets/fonts/IBMPlexSans-Regular.ttf');
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('./assets/fonts/IBMPlexMono-Regular.ttf');
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  box-shadow: none;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.spinner {
  width: 32px;
  height: 32px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--ultra-light-gray);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
  cursor: not-allowed;
  pointer-events: none;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

:root {
  --font-bold: 'IBM Plex Sans Bold';
  --font-regular: 'IBM Plex Sans';
  --font-mono-regular: 'IBM Plex Mono';

  --green: #26aa2c;
  --ultra-light-gray: #525252;
  --light-gray: #484848;
  --dark-gray: #424242;
  --red: #ef6053;
  --orange: #ffa800;
  --green-hover: #21c129;
  --red-hover: #da4b4b;
}
