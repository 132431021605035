.docs label,
.docs input,
.docs input::placeholder {
  font-family: var(--font-mono-regular);
  color: var(--ultra-light-gray);
}

.docs input::-webkit-outer-spin-button,
.docs input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.docs {
  width: 100%;
  min-height: 100vh;
  background-color: #f2f3f3;
}

.docs .container {
  max-width: 1180px;

  margin: 0 auto;
  padding: 0 1rem 3rem 1rem;
}

.docs .container .tables {
  min-height: 600px;
  column-gap: 1rem;
}

.docs .container .tables .box {
  border: 1px solid #d8d8d8;
  background-color: #fff;
}

.docs .container .tables .main {
  width: 100%;
}

.docs .container .tables .main .content {
  padding: 2rem;
  width: 100%;
  overflow-x: auto;
}

.docs .container .tables .main .content .table__warning {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  padding: 2rem 0;
}

.docs .container .tables .main .content .table__warning h1 {
  font-family: var(--font-mono-regular);
  font-weight: 400;
  color: #8a8a8a;
  font-size: 1.125rem;
  text-align: center;
}

.docs .container .tables .main .content .actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.75rem;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.docs .container .tables .main .content table {
  width: 100%;

  font-family: var(--font-mono-regular);

  margin-bottom: 2rem;
}

.docs .container .tables .main .content .table__footer {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.docs .container .tables .main .content table tbody:before {
  padding-left: 1rem;
  line-height: 1rem;
  content: '.';
  color: white;
  display: block;
}

.docs .container .tables .main .content table tbody tr td {
  padding: 5px 0;
}

.docs .container .tables .main .content table thead tr th {
  padding: 0 0 0 10px;
}

.docs .container .tables .main .content table thead tr th:nth-child(1) {
  padding: 0px;
}

.docs .container .tables .main .content table thead tr th:nth-child(2) {
  padding: 0 10px;
}

.docs .container .tables .main .content table input[type='checkbox'] {
  position: relative;
  cursor: pointer;
}

.docs .container .tables .main .content table input[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: #e9e9e9;
}

.docs .container .tables .main .content table input[type='checkbox']:checked:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: #1e80ef;
}

.docs .container .tables .main .content table input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1px;
  left: 5px;
}

.docs .container .tables .main .content form {
  display: flex;
  flex-direction: row;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  width: 100%;
  align-items: center;
}

.docs .container .tables .main .content form button {
  padding: 0 17px;
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  width: 56px;
}

.docs .container .tables .main .content form button#clear {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: self-end;

  width: 56px;
  height: 56px;

  cursor: pointer;

  background-color: #44679b;

  padding: 0 17px;
  border: none;
  outline: none;
}

.docs .container .tables .main .content form button#searchButton {
  width: 56px;
  height: 56px;
}

.docs .container .tables .main .content form .input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d8d8d8;
  padding: 1rem;
  min-width: 200px;
  height: 56px;
}

.docs .container .tables .main .content form label {
  padding: 0 0 0.5rem 0.5rem;
  white-space: nowrap;
}

.docs .container .tables .main .content form .input input {
  border: none;
  outline: none;
  width: 90%;
}

.docs .container .tables .main .content form .input input[disabled] {
  background-color: #fff;
  color: #ddd;
}

.docs .container .tables .main .content form .input input[disabled]::placeholder {
  color: #ddd;
}

.docs .container .tables .main .content .loading {
  width: 32px;
  height: 32px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--ultra-light-gray);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.docs .container .tables .main .content form .button__md {
  display: block;
}

.docs .container .tables .main .content form .button__sm {
  display: none !important;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 425px) {
  .docs .container .tables .main .content {
    padding: 1rem;
  }
}

@media (max-width: 722px) {
  .docs .container .tables .main .content form {
    display: flex;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    width: 100%;
    flex-wrap: wrap;
    justify-content: unset;
  }

  .docs .container .tables .main .content form {
    width: 100%;
  }

  .docs .container .tables .main .content form .note__n {
    max-width: 100%;
  }

  .docs .container .tables .main .content form .button__md {
    display: none !important;
  }

  .docs .container .tables .main .content form .button__sm {
    display: flex !important;
  }
}
